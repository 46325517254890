import React, { Component, useEffect, useState } from 'react'
// import { Doughnut } from 'react-chartjs-2';
import CountUp from 'react-countup'
import axios from 'axios'
import Atropos from 'atropos/react'
import admindata from '../components/admindata'
import './Dashboard.css'
import { Link, useHistory } from 'react-router-dom'
import Conflictgame from './Conflictgame'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut, Bar } from 'react-chartjs-2'
import { Line } from 'react-chartjs-2'
import { yellow } from '@mui/material/colors'
import Loader from 'react-js-loader'
import loader from '../icons/loder.gif'
import socket from '../../socket/socket'
import { apiRequest } from '../store/allApi'

const $ = require('jquery')
$.datatable = require('datatables.net')

const Dashboard = () => {
  const [loading, setLoading] = useState(false)

  const [DOB, setDob] = useState("");


  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const history = useHistory()
  const [liveUser, setliveUser] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [Admin, setAdmin] = useState(0)
  const [allData, setAllData] = useState([])
  const [todayAllData, setTodayAllData] = useState([])
  const [olddayaData, set30DayaAllData] = useState([])
  const [today, setToday] = useState(false)
  const [User, setUser] = useState(0)
  const [ACTIVE, setACTIVE] = useState(0)
  const [BLOCKED, setBLOCKED] = useState(0)
  const [COMPLETED, setCOMPLETED] = useState(0)
  const [ACTIVE1, setACTIVE1] = useState(0)
  const [ONGOING, setONGOING] = useState(0)
  const [CANCELLED, setCANCELLED] = useState(0)
  const [totalDeposit, setTotalDeposit] = useState(0)
  const [totalPending, setTotalPending] = useState(0)
  const [totalRejected, setTotalRejected] = useState(0)
  const [totalWithdrawl, setTotalWithdrawl] = useState(0)
  const [totalPendingWithdrawl, setTotalPendingWithdrawl] = useState(0)
  const [totalRejectedWithdrawl, setTotalRejectedWithdrawl] = useState({
    count: 0
  })
  const [todayData, setTodayData] = useState()

  const totalUsers = User
  const totalAdmin = Admin
  const totalUsers1 = ACTIVE + BLOCKED
  const totalChallange = COMPLETED + ACTIVE1 + ONGOING + CANCELLED
  const TotalDeposit =
    totalDeposit?.count + totalPending?.count + totalRejected?.count
  const TotalWithdrawal =
    totalWithdrawl?.count +
    totalPendingWithdrawl?.count +
    totalRejectedWithdrawl?.count
  const TotalWithdrawalMoney = totalWithdrawl
  const TotaldepositeMoney = totalDeposit

  const adminPercentage = (Admin / totalAdmin) * 100
  const userPercentage = (User / totalUsers) * 100
  const activePercentage = (ACTIVE / totalUsers1) * 100
  const blockedPercentage = (BLOCKED / totalUsers1) * 100
  const completedPercentage = (COMPLETED / totalChallange) * 100
  const active1Percentage = (ACTIVE1 / totalChallange) * 100
  const ongoingPercentage = (ONGOING / totalChallange) * 100
  const cancelledPercentage = (CANCELLED / totalChallange) * 100
  const totalDepositPercentage = (totalDeposit?.count / TotalDeposit) * 100
  const totalDeposit1Percentage = (totalDeposit / TotaldepositeMoney) * 100
  const totalPendingpercentage = (totalPending?.count / TotalDeposit) * 100
  const totalRejectedPercentage = (totalRejected?.count / TotalDeposit) * 100
  const totalWithdrawlPercentage =
    (totalWithdrawl?.count / TotalWithdrawal) * 100
  const totalWithdrawl1Percentage =
    (totalWithdrawl / TotalWithdrawalMoney) * 100
  const totalPendingWithdrawlPercentage =
    (totalPendingWithdrawl?.count / TotalWithdrawal) * 100
  const totalRejectedWithdrawlPercentage =
    (totalRejectedWithdrawl?.count / TotalWithdrawal) * 100

  const admin = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/total_admin_get`, { headers }).then(res?.data? => {
    //   setAdmin(res?.data)
    // })
    try {
      const res = await apiRequest(`admin/total_admin_get`,"GET",null,headers)
      setAdmin(res?.data)
        } catch (e) {
          console.log(e)
        }
  }
  const TodayAll = async () => {
    

    const currentDate = new Date().toISOString().split('T')[0];


    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }


    const urlDate = DOB ? `admin/admin_allData_get?startDate=${DOB}` : `admin/admin_allData_get`;

    // axios.get(urlDate,{ headers: headers }).then(res?.data? => {

    // axios.get(urlDate,{ headers: headers }).then(res?.data? => {
      // console.log(res?.data?.alldata[0], "vhh")

      try {
    const res = await apiRequest(urlDate,"GET",null,headers)
        setAllData(res?.data?.alldata[0])
        setTodayAllData(res?.data?.thirtyDaysData[0])
        set30DayaAllData(res?.data?.thirtyDaysData)
      } catch (e) {
        console.log(e)
      }
    // })
  }

  const user123 = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/total/user_data_get`, { headers }).then(res?.data? => {
    //   setUser(res?.data)
    // })
    try {
      const res = await apiRequest(`admin/total/user_data_get`,"GET",null,headers)
      setUser(res?.data)
        } catch (e) {
          console.log(e)
        }
  }

  const actives = async () => {
    setLoading(true)
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios
    //   .get(baseUrl + `admin/total/active_user`, { headers })
    //   .then(res?.data? => {
    //     setLoading(false)
    //     setACTIVE(res?.data)
    //   })
    //   .catch(e => {
    //     setLoading(false)
    //     if (e.res?.data?ponse.status === 401) {
    //       localStorage.removeItem('token')
    //       history.push('/adminlogin')
    //       //place your reentry code
    //     }
    //   })
    try {
      const res = await apiRequest('admin/total/active_user',"GET",null,headers)
      setLoading(false)
          setACTIVE(res?.data)
        } catch (e) {
          setLoading(false)
              if (e.res?.data.status === 401)  {
                localStorage.removeItem('token')
                history.push('/adminlogin')
                //place your reentry code
              }
        }
      
  }

  const blockUser = () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    try {
      const res = apiRequest('admin/total/block_user_get',"GET",null,headers)
      setBLOCKED(res?.data)
    } catch (e) {
      console.log(e);
    }
  }
   
  useEffect(() => {
   
    blockUser()
 
  }, [])

  useEffect(() => {
    TodayAll()
  }, [DOB])

  // CHALLANGE OVERVIEW

  const comp = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/challange_completed_data_get`, { headers }).then(res?.data? => {
    //   setCOMPLETED(res?.data)
    // })
    try {
    const res = await apiRequest('admin/challange_completed_data_get',"GET",null,headers)
    setCOMPLETED(res?.data)
    } catch (e) {
      console.log(e)
    }

  }

  const active =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/active/challange_get`, { headers }).then(res?.data? => {
    //   setACTIVE1(res?.data)
    // })
    try {
      const res = await apiRequest('admin/active/challange_get',"GET",null,headers)
               setACTIVE1(res?.data)
        } catch (e) {
          console.log(e)
        }

  }

  const ongoings =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/challange/running_active`, { headers }).then(res?.data? => {
    //   setONGOING(res?.data)
    // })
    try {
      const res = await apiRequest(`admin/challange/running_active`,"GET",null,headers)
      setONGOING(res?.data)
        } catch (e) {
          console.log(e)
        }
  }

  const cancelled = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/get_challange/cancelled`, { headers }).then(res?.data? => {
    //   setCANCELLED(res?.data)
    // })
    try {
      const res = await apiRequest(`admin/get_challange/cancelled`,"GET",null,headers)
      setCANCELLED(res?.data)
        } catch (e) {
          console.log(e)
        }
  }

  // deposite api start

  const totalDepositfunc = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/get_total/deposit`, { headers }).then(res?.data? => {
    //   setTotalDeposit(res?.data)
    // })
    try {
      const res = await apiRequest(`admin/get_total/deposit`,"GET",null,headers)
      setTotalDeposit(res?.data)
        } catch (e) {
          console.log(e)
        }
  }
  const totalPendingfunc =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/get_count/new/deposit`, { headers }).then(res?.data? => {
    //   setTotalPending(res?.data)
    // })
    try {
      const res = await apiRequest('admin/get_count/new/deposit',"GET",null,headers)
      setTotalPending(res?.data)
        } catch (e) {
          console.log(e)
        }
  }
  const totalRejectedfunc =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/count/rejected/get_deposit`, { headers }).then(res?.data? => {
    //   setTotalRejected(res?.data)
    // })
    try {
      const res = await apiRequest('admin/count/rejected/get_deposit',"GET",null,headers)
      setTotalRejected(res?.data)
        } catch (e) {
          console.log(e)
        }
  }

  // deposite api end

  // withdrawl api start

  const totalWithdrawlfunc =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/get_total/withdraw`, { headers }).then(res?.data? => {
    //   setTotalWithdrawl(res?.data)
    // })
    try {
      const res = await apiRequest('admin/get_total/withdraw',"GET",null,headers)
      setTotalWithdrawl(res?.data)
        } catch (e) {
          console.log(e)
        }
  }
  const totalPendingWithdrawlfunc =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/get_count_new/withdrawl`, { headers }).then(res?.data? => {
    //   setTotalPendingWithdrawl(res?.data)
    // })
    try {
      const res = await apiRequest('admin/get_count_new/withdrawl',"GET",null,headers)
      setTotalPendingWithdrawl(res?.data)
        } catch (e) {
          console.log(e)
        }
  }
  const totalRejectedWithdrawlfunc =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `admin/count_rejected/withdrawl`, { headers }).then(res?.data? => {
    //   setTotalRejectedWithdrawl(res?.data)
    // })
    try {
      const res = await apiRequest('admin/count_rejected/withdrawl',"GET",null,headers)
      setTotalRejectedWithdrawl(res?.data)
        } catch (e) {
          console.log(e)
        }
  }

  // witdrawl api end

  const [Some, setSome] = useState()
  const Some1 = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios.get(baseUrl + `challange/some`, { headers }).then(res?.data? => {
    //   setSome(res?.data)
    //   $('table')Table()
    // })
    try {
      const res = await apiRequest('challange/some',"GET",null,headers)
      setSome(res?.data)
      $('table').dataTable()
        } catch (e) {
          console.log(e)
        }
  }

  const todayApi = async (s, e) => {
    setLoading(true)
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // axios
    //   .get(baseUrl + `admin/all_user/data_get?startingfrom=` + s + '&endto=' + e, {
    //     headers
    //   })
    //   .then(res?.data? => {
    //     setTodayData(res?.data)
    //     setLoading(false)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     setLoading(false)
    //   })

    try {
      const res = await apiRequest(`admin/all_user/data_get?startingfrom=` + s + '&endto=' + e,"GET",null,headers)
      setTodayData(res?.data)
      setLoading(false)
        } catch (e) {
          console.log(e)
          setLoading(false)
        }
  }

  function filterData () {
    todayApi(new Date(startDate), new Date(endDate))
  }

  function daterange (s, e) {
    if (s > e) {
      alert('Starting date is greater than ending date')
    } else {
      setStartDate(s)
      setEndDate(e)
    }
    console.log(s, e)
    // Change start date format
    var startDate = new Date(s)
    var startDay = startDate.getDate()
    var startMonth = startDate.getMonth() + 1 // Months are zero-based
    var startYear = startDate.getFullYear()
    var startHours = startDate.getHours()
    var startMinutes = startDate.getMinutes()
    var startSeconds = startDate.getSeconds()

    // Change end date format
    var endDate = new Date(e)
    var endDay = endDate.getDate()
    var endMonth = endDate.getMonth() + 1 // Months are zero-based
    var endYear = endDate.getFullYear()
    var endHours = endDate.getHours()
    var endMinutes = endDate.getMinutes()
    var endSeconds = endDate.getSeconds()
  }

  // ...

  useEffect(() => {
    comp()
    actives()
    user123()
    admin()
    TodayAll()
    active()
    ongoings()
    cancelled()
    totalDepositfunc()
    totalPendingfunc()
    totalRejectedfunc()
    totalWithdrawlfunc()
    totalPendingWithdrawlfunc()
    totalRejectedWithdrawlfunc()
    todayApi(new Date(new Date().setHours(0, 0, 0, 0)), new Date())

    // Some1()
  }, [])

  const data = {
    labels: ['Admin', 'Active User', 'InActive User', 'Total User', 'Others'],
    datasets: [
      {
        data: [Admin, ACTIVE, User - ACTIVE, User, 12], // Example values for each slice
        backgroundColor: [
          '#a343ff',
          '#00b3ff',
          '#f5334f',
          '#30c102',
          '#FFEB3B'
        ],
        hoverBackgroundColor: [
          '#a343ff',
          '#00b3ff',
          '#f5334f',
          '#30c102',
          '#FFEB3B'
        ],
        borderWidth: 0
      }
    ]
  }

  const options = {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 15, // Set the width of the legend box
        fontSize: 8, // Set the font size of the legend labels
        fontColor: 'white'
      }
    }
  }

  

  // const labels = olddayaData?.map(data => data?.updatedAt);
  // const labels = olddayaData?.map(data => {
  //   const dateObject = new Date(data?.updatedAt);
  //   const month = dateObject.toLocaleString('default', { month: 'long' });
  //   const day = dateObject.getDate();
  //   return `${month} ${day}`;
  // });

  

  const labels = olddayaData?.map(data => {
    const dateObject = new Date(data?.updatedAt);
    const monthNumber = dateObject.getMonth() + 1; // Adding 1 to get month number (0-indexed)
    const day = dateObject.getDate();
    return `${day}/${monthNumber}`;
  });

  const baroptions = {
    res: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            fontColor: 'white' // Set the color of the y-axis tick labels
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: 'white' // Set the color of the y-axis tick labels
          }
        }
      ]
    },
    legend: {
      display: false,
      position: 'top'
    }
  }

  const bardata = {
    labels,
    datasets: [
      {
        label: 'Earning',
        data:  olddayaData?.map(data => data?.admin_commision),
        backgroundColor: [
          '#a343ff',
          '#00b3ff',
          '#f5334f',
          '#30c102',
          '#FFEB3B',
          'blue',
          'white',
          '#a343ff',
          '#00b3ff',
          '#f5334f',
          '#30c102',
          '#FFEB3B',
          'blue',
          'white',
          '#a343ff',
          '#00b3ff',
          '#f5334f',
          '#30c102',
          '#FFEB3B',
          'blue',
          'white',
          '#a343ff',
          '#00b3ff',
          '#f5334f',
          '#30c102',
          '#FFEB3B',
          'blue',
          'white',
          '#a343ff',
          '#00b3ff',
        ]
      }
    ]
  }
  const datachart = {
    labels,
    datasets: [
      {
        label: "Admin Earning",
        data:  olddayaData?.map(data => data?.admin_commision),
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        fontColor: "white"
      },
      // {
      //   label: "Second dataset",
      //   data: [33, 25, 35, 51, 54, 76],
      //   fill: false,
      //   borderColor: "#742774"
      // }
    ]
  };
  useEffect(() => {
    if (socket && socket.connected) {
      socket.on('liveUsers', (data) => {
        alert(1);
        console.log(data);
      });
    }
  
    return () => {
      if (socket && socket.connected) {
        socket.off('liveUsers');
      }
    };
  }, []);

  return loading ? (
    <>
      <div className='App'>
        <div className='item dashboaed_loader'>
          <Loader
            type='bubble-ping'
            bgColor={'#fff'}
            title={<img src={loader} alt='Loading...' />}
            color={'#000'}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <div className=''>
      {/* dashboard boxes */}
      <div className='row mt-3'>
        <div className='col-xl-12 col-sm-12 d_flex_both_center'>
          <h1 className='dashboard_heading'> All User Overview</h1>
          <div className='custom-control custom-switch float-right'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='customSwitch1'
              onClick={() => setToday(value => !value)}
            />
            <label
              className='custom-control-label today_overview'
              htmlFor='customSwitch1'
            >
              {today == false ? 'OVERVIEW' : 'TODAY'}
            </label>
          </div>
        </div>
      </div>

      {/* overview data */}
      {!today && (
        <div>
          <div className='row'>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Admin</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={Admin && Admin}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                      Total Admin
                      <span className='float-right badge badge-pill badge-danger'>
                     {/* {adminPercentage === 'number' ? `${adminPercentage.toFixed(2)}%` : '100%'} */}
                     100%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger  '
                        role='progres?.data?sbar'
                        // style={{ width: `${adminPercentage}%` }}
                        style={{ width: `100%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total User</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.totalUser}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total User
                      <span className='float-right badge badge-pill badge-yellow'>
                        {userPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow'
                        role='progres?.data?sbar'
                        style={{ width: `${userPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Active User</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={ACTIVE && ACTIVE}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Active User
                      <span className='float-right badge badge-pill badge-primary'>
                        {activePercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary'
                        role='progres?.data?sbar'
                        style={{ width: `${activePercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>InActive User</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={ACTIVE && User - ACTIVE}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                      InActive User
                      <span className='float-right badge badge-pill badge-primary'>
                        {activePercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary'
                        role='progres?.data?sbar'
                        style={{ width: `${activePercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>UnBlocked User</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={User && User - BLOCKED}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    UnBlocked User
                      <span className='float-right badge badge-pill badge-primary'>
                        {activePercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary'
                        role='progres?.data?sbar'
                        style={{ width: `${activePercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Blocked User</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={BLOCKED && BLOCKED}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Blocked User
                      <span className='float-right badge badge-pill badge-secondary'>
                        {blockedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${blockedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Penelty</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.totalpeneltyAmount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Penelty
                      <span className='float-right badge badge-pill badge-secondary'>
                        {blockedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${blockedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Bonus</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.totalbonusAmount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Bonus
                      <span className='float-right badge badge-pill badge-secondary'>
                        {blockedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${blockedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {admindata?.user?.usertype == 'Admin' ? (
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Commission</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.admin_commision}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Commission
                      <span className='float-right badge badge-pill badge-secondary'>
                        {blockedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${blockedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             ) : (
              ""
             )}
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Deposit Amount</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.totaldepositamount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Deposit Amount
                      <span className='float-right badge badge-pill badge-secondary'>
                        {blockedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${blockedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Withdrawal Amount</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.totalwithdrawamount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Withdrawal Amount
                      <span className='float-right badge badge-pill badge-secondary'>
                        {blockedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${blockedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Referral Reedem</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.referralreedem}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Referral Reedem
                      <span className='float-right badge badge-pill badge-secondary'>
                        {blockedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${blockedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'> CHALLANGE OVERVIEW</h1>
            </div>
            {/* <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> ACTIVE CHALLANGE</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={ACTIVE1 && ACTIVE1}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                      ACTIVE CHALLANGE
                      <span className='float-right badge badge-pill badge-danger'>
                        {active1Percentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger  '
                        role='progres?.data?sbar'
                        style={{ width: `${active1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Success Game</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.games_success}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Success Game
                      <span className='float-right badge badge-pill badge-yellow'>
                        {completedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow  '
                        role='progres?.data?sbar'
                        style={{ width: `${completedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> ONGOING CHALLANGE</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={ONGOING && ONGOING}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                      ONGOING CHALLANGE
                      <span className='float-right badge badge-pill badge-primary'>
                        {ongoingPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary '
                        role='progres?.data?sbar'
                        style={{ width: `${ongoingPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Cancelled Game</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={allData && allData?.games_cancel}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Cancelled Challanges
                      <span className='float-right badge badge-pill badge-secondary'>
                        {cancelledPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary'
                        role='progres?.data?sbar'
                        style={{ width: `${cancelledPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'> Deposit Overview</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Total Gateway Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalDeposit && totalDeposit?.count}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Gateway Deposit
                      <span className='float-right badge badge-pill badge-danger'>
                        {totalDepositPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger '
                        role='progres?.data?sbar'
                        style={{ width: `${totalDepositPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Refferal Earning Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalDeposit && totalDeposit}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Refferal Earning Deposit
                      <span className='float-right badge badge-pill badge-yellow'>
                        {totalDeposit1Percentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `${totalDeposit1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Total Bonus Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalPending && totalPending?.count}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Bonus Deposit
                      <span className='float-right badge badge-pill badge-primary'>
                        {totalPendingpercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary  '
                        role='progres?.data?sbar'
                        style={{ width: `${totalPendingpercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total Gateway Reverse</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalRejected && totalRejected?.count}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Gateway Reverse
                      <span className='float-right badge badge-pill badge-secondary'>
                        {totalRejectedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${totalRejectedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'> Deposit Request Overview</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Total Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalDeposit && totalDeposit?.count}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                      Total Request
                      <span className='float-right badge badge-pill badge-danger'>
                      {typeof totalDepositPercentage === "number" ? ` ${totalDepositPercentage.toFixed(2)}% `: "0%"}
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger '
                        role='progres?.data?sbar'
                        style={{ width: `${totalDepositPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Success Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalDeposit?.count - (totalPending?.count + totalRejected?.count)}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Success Request
                      <span className='float-right badge badge-pill badge-yellow'>
                      {typeof totalDepositPercentage === "number" ? ` ${totalDepositPercentage.toFixed(2)}% `: "0%"}
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `${totalDeposit1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Pending Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalPending && totalPending?.count}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Pending Request
                      <span className='float-right badge badge-pill badge-primary'>
                        {totalPendingpercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary  '
                        role='progres?.data?sbar'
                        style={{ width: `${totalPendingpercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Drop Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalRejected && totalRejected?.count}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Drop Request
                      <span className='float-right badge badge-pill badge-secondary'>
                        {totalRejectedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${totalRejectedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'>TOTAL WITHDRAWL OVERVIEW</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Total Wallet Withdrawal</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalWithdrawl && totalWithdrawl}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Wallet Withdrawal
                      <span className='float-right badge badge-pill badge-yellow'>
                        {totalWithdrawl1Percentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `${totalWithdrawl1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Total Refferal Earning Withdrawal</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalWithdrawl && totalWithdrawl}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Refferal Earning Withdrawal
                      <span className='float-right badge badge-pill badge-yellow'>
                        {totalWithdrawl1Percentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `${totalWithdrawl1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'>WITHDRAWL REQUEST OVERVIEW</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Total Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalWithdrawl && totalWithdrawl?.count}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total Request
                      <span className='float-right badge badge-pill badge-danger'>
                        {totalWithdrawlPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger'
                        role='progres?.data?sbar'
                        style={{ width: `${totalWithdrawlPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Success Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        {/* ₹ */}
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={totalWithdrawl?.count - (totalPendingWithdrawl?.count + totalRejectedWithdrawl?.count)}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Success Request
                      <span className='float-right badge badge-pill badge-yellow'>
                        {totalWithdrawl1Percentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `${totalWithdrawl1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Pending Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={
                            totalPendingWithdrawl && totalPendingWithdrawl?.count
                          }
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Pending Request
                      <span className='float-right badge badge-pill badge-primary'>
                        {totalPendingWithdrawlPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary '
                        role='progres?.data?sbar'
                        style={{ width: `${totalPendingWithdrawlPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Cancelled Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={
                            totalRejectedWithdrawl && totalRejectedWithdrawl?.count
                          }
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Cancelled Request
                      <span className='float-right badge badge-pill badge-secondary'>
                        {totalRejectedWithdrawlPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{
                          width: `${totalRejectedWithdrawlPercentage}%`
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-xl-6 col-sm-12'>
              <div className='card dashboard_small_card  dashboard_graph_card'>
                <div className='grapg_header'>
                  <h2 className=''>All User</h2>
                </div>
                <div className='graph_box'>
                  <Doughnut data={data} options={options} />
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-sm-12'>
              <div className='card dashboard_small_card  dashboard_graph_card'>
                <div className='grapg_header'>
                  <h2 className=''>Daily Earnings</h2>
                </div>
                <div className='graph_box'>
                  {/* <Bar data={bardata} options={baroptions} /> */}
                  <Line data={datachart} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Today data */}
      {today && (
        <div>

<div className='mb-3'>
            <input
              type="date"
              name="DOB"
              placeholder="Date of Birth"
              value={DOB}
              onChange={e => setDob(e.target.value)}
              required
            />
          </div>

          <div className='row'>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Today User</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.totalUser}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today User
                    <span className='float-right badge badge-pill badge-danger'>
                        {/* {adminPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger  '
                        role='progres?.data?sbar'
                        // style={{ width: `${adminPercentage}%` }}
                        style={{ width: "0px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>User Wallet Balance</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.totalWalletbalance}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    User Wallet Balance
                    <span className='float-right badge badge-pill badge-danger'>
                        {adminPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger  '
                        role='progres?.data?sbar'
                        style={{ width: `${adminPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Today Refferel Earning</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.refer_commision}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Refferel Earning
                      <span className='float-right badge badge-pill badge-primary'>
                        {/* {ongoingPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary '
                        role='progres?.data?sbar'
                        // style={{ width: `${ongoingPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Refferal Wallet Balance</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.totalReferralWallet}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Refferal Wallet Balance
                      <span className='float-right badge badge-pill badge-secondary'>
                        {/* {cancelledPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary'
                        role='progres?.data?sbar'
                        // style={{ width: `${cancelledPercentage}%` }}
                        style={{ width: `0` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            {admindata?.user?.usertype == 'Admin' ? (
              <>
              
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Today Ludo Commission</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.admin_commision}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Ludo Commission
                      <span className='float-right badge badge-pill badge-yellow'>
                        {/* {totalDeposit1Percentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalDeposit1Percentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Today Aviator Commission</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.aviatorProfitAmount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Aviator Commission
                      <span className='float-right badge badge-pill badge-primary'>
                        {/* {totalPendingpercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary  '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalPendingpercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Today Total Commission</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.admin_commision + todayAllData.aviatorProfitAmount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Total Commission
                      <span className='float-right badge badge-pill badge-primary'>
                        {/* {totalPendingpercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary  '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalPendingpercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              </>
            
            ) : (
             ""
            )}

          
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Today Bonus</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.totalbonusAmount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Bonus
                      <span className='float-right badge badge-pill badge-primary'>
                        {/* {totalPendingpercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary  '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalPendingpercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Today Total Penelty</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.totalpeneltyAmount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Total Penelty
                      <span className='float-right badge badge-pill badge-secondary'>
                        {totalRejectedPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{ width: `${totalRejectedPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>

          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'>TODAY GAME OVERVIEW</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Today Game</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.totalgames
                          }
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Game
                      <span className='float-right badge badge-pill badge-yellow'>
                        {/* {userPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow'
                        role='progres?.data?sbar'
                        // style={{ width: `${userPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Today Success Game</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.games_success}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Success Game
                      <span className='float-right badge badge-pill badge-primary'>
                        {/* {activePercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary'
                        role='progres?.data?sbar'
                        // style={{ width: `${activePercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Today Cancelled Game</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.games_cancel}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Cancelled Game
                      <span className='float-right badge badge-pill badge-secondary'>
                      0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        // style={{ width: `${blockedPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'> CHALLANGE OVERVIEW</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Active Challange</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayActiveChallange}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Active Challange
                      <span className='float-right badge badge-pill badge-danger'>
                        {/* {active1Percentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger  '
                        role='progres?.data?sbar'
                        // style={{ width: `${active1Percentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Ongoing Challange</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayOngingChallange}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Ongoing Challange
                      <span className='float-right badge badge-pill badge-yellow'>
                        {/* {completedPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow  '
                        role='progres?.data?sbar'
                        // style={{ width: `${completedPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'> Deposit Overview</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Today Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.totaldepositamount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Deposit
                      <span className='float-right badge badge-pill badge-danger'>
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger '
                        role='progres?.data?sbar'
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Rozorpay Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayrazorpaydeposit}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Rozorpay Deposit
                      <span className='float-right badge badge-pill badge-danger'>
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger'
                        role='progres?.data?sbar'
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Pinelab Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todaypinelabdeposit}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Pinelab Deposit
                      <span className='float-right badge badge-pill badge-yellow'>
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Phonepe Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={
                            todayData && todayData.todayphonepedeposit
                          }
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Phonepe Deposit
                      <span className='float-right badge badge-pill badge-primary'>
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalPendingWithdrawlPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Cashfree Deposit</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={
                            todayData && todayData.todaycashfreedeposit
                          }
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Cashfree Deposit
                      <span className='float-right badge badge-pill badge-secondary'>
                        {/* {totalRejectedWithdrawlPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{
                          // width: `${totalRejectedWithdrawlPercentage}%`
                          width: `0%`
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'> Deposit Request Overview</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Total No. Of Deposit Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={
                            todayAllData && todayAllData.totaldeposit
                          }
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Total No. Of Deposit Request
                      <span className='float-right badge badge-pill badge-primary'>
                        {/* {totalPendingWithdrawlPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary '
                        role='progres?.data?sbar'
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>Success Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todaySuccessDepositRequest}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Success Request
                      <span className='float-right badge badge-pill badge-danger'>
                        {/* {totalDepositPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalDepositPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Drop Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayDropDepositRequest}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Drop Request
                      <span className='float-right badge badge-pill badge-danger'>
                        {/* {totalWithdrawlPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger'
                        role='progres?.data?sbar'
                        // style={{ width: `${totalWithdrawlPercentage}%` }}
                        style={{ width: `0` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Pending Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayPendingDepositRequest}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Pending Request
                      <span className='float-right badge badge-pill badge-yellow'>
                        {/* {totalWithdrawl1Percentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalWithdrawl1Percentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           

          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'>WITHDRAWL REQUEST OVERVIEW</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>  Today Withdrawal Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.totalwithdraw}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Withdrawal Request
                      <span className='float-right badge badge-pill badge-danger'>
                        {/* {totalWithdrawlPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger'
                        role='progres?.data?sbar'
                        // style={{ width: `${totalWithdrawlPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Success Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayWithdrawalSuccessRequest}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Success Request
                      <span className='float-right badge badge-pill badge-yellow'>
                        {/* {totalWithdrawl1Percentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalWithdrawl1Percentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Pending Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayWithdrawalPendingRequest}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Pending Request
                      <span className='float-right badge badge-pill badge-primary'>
                        {/* {totalPendingWithdrawlPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary '
                        role='progres?.data?sbar'
                        // style={{ width: `${totalPendingWithdrawlPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Cancelled Request</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayWithdrawalCancelledRequest}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Cancelled Request
                      <span className='float-right badge badge-pill badge-secondary'>
                        {/* {totalRejectedWithdrawlPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-secondary '
                        role='progres?.data?sbar'
                        style={{
                          // width: `${totalRejectedWithdrawlPercentage}%`
                          width: `0%`
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'>TOTAL WITHDRAWAL OVERVIEW</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>  Today Total Withdrawal</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayAllData && todayAllData.totalwithdrawamount}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Total Withdrawal
                      <span className='float-right badge badge-pill badge-danger'>
                        {/* {totalWithdrawlPercentage.toFixed(2)}% */}
                        0%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger'
                        role='progres?.data?sbar'
                        // style={{ width: `${totalWithdrawlPercentage}%` }}
                        style={{ width: `0%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Today Wallet Withdrawal</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.todayTotalWalletWithdraw}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Wallet Withdrawal
                      <span className='float-right badge badge-pill badge-yellow'>
                        {totalWithdrawl1Percentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `${totalWithdrawl1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Today Reffer Withdrawal</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={
                            todayData && todayData.totalReferralWallet
                          }
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Today Reffer Withdrawal
                      <span className='float-right badge badge-pill badge-primary'>
                        {totalPendingWithdrawlPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-primary '
                        role='progres?.data?sbar'
                        style={{ width: `${totalPendingWithdrawlPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div className='row'>
            <div className='col-xl-12 col-sm-12'>
              <h1 className='dashboard_heading'>TODAY HOLD  BALANCE OVERVIEW</h1>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'>  Game Hold Balance</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                      ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.totalHoldBalance}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Game Hold Balance
                      <span className='float-right badge badge-pill badge-danger'>
                        {totalWithdrawlPercentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-danger'
                        role='progres?.data?sbar'
                        style={{ width: `${totalWithdrawlPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-sm-6'>
              <div className='card dashboard_small_card card_hover'>
                <div className='card-header border-bottom-0'>
                  <h3 className='card-title'> Withdrawal Hold Balance</h3>
                  <div className='card-options'> </div>
                </div>
                <div className='card-body pt-0'>
                  <div className=''>
                    <div className=''>
                      <h3 className='mb-2 font-rubik'>
                        ₹
                        <CountUp
                          start={0}
                          delay={0.1}
                          duration={0.2}
                          end={todayData && todayData.totalWithdrawHold}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-muted'>
                    Withdrawal Hold Balance
                      <span className='float-right badge badge-pill badge-yellow'>
                        {totalWithdrawl1Percentage.toFixed(2)}%
                      </span>
                    </p>
                    <div className=' progres?.data?s h-2'>
                      <div
                        className='progres?.data?s-bar bg-yellow '
                        role='progres?.data?sbar'
                        style={{ width: `${totalWithdrawl1Percentage}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>

          <div className='row mt-3'>
            <div className='col-xl-6 col-sm-12'>
              <div className='card dashboard_small_card  dashboard_graph_card'>
                <div className='grapg_header'>
                  <h2 className=''>All User</h2>
                </div>
                <div className='graph_box'>
                  <Doughnut data={data} options={options} />
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-sm-12'>
              <div className='card dashboard_small_card  dashboard_graph_card'>
                <div className='grapg_header'>
                  <h2 className=''>Monthly Earnings</h2>
                </div>
                <div className='graph_box'>
                  <Bar data={bardata} options={baroptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Conflictgame />

         <div className='row'>
        <div className='col-md-4 grid-margin stretch-card'></div>
      </div>
    </div>
  )
}

export default Dashboard
