import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactSwitch from 'react-switch'
import axios from 'axios'
import Swal from 'sweetalert2'
import { apiRequest } from '../../store/allApi'


const Gateway = () => {

//////////URL//////////////
const [paynow1Url, SetPayNow1Url] = useState('')
const [paynow2Url, SetPayNow2Url] = useState('')
const [paynow3Url, SetPayNow3Url] = useState('')
const [paynow4Url, SetPayNow4Url] = useState('')
const [paynow5Url, SetPayNow5Url] = useState('')

  const [checkedRozorPayIn, setCheckedRozorPayIn] = useState(true)
  const [checkedRozorPay, setCheckedRozorPay] = useState(true)
  const [checkedQroPayIn, setCheckedQroPayIn] = useState(true)
  const [checkedQroPay, setCheckedQroPay] = useState(true)
  const [isKvmpayInActive, setisKvmpayInActive] = useState(true)
  const [isUpiGateway, setIsUpiGateway] = useState(true)
  const [isKhelstarUpiGateway, setKhelStar] = useState(true)
  const [isKvmpayOutActive, setisKvmpayOutActive] = useState(true)
  const [checkedPhonepeIn, setCheckedPhonepeIn] = useState(true)
  
  const [ispaytmWithdrawal, setIspaytmWithdrawal] = useState(true)
  const [RazorPayout, setRazorpayout] = useState(true)
  const [RazorDeposit, setRazorDeposit] = useState(true)
  const [RazorpayAuto, setRazorpayAuto] = useState(false)
  const [RazorPayKey, setRazorpayKey] = useState()
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState()
  const [AccountName, setAccountName] = useState()
  const [decentroPayout, setdecentropayout] = useState(true)
  const [decentroDeposit, setdecentroDeposit] = useState(true)
  const [pinelabDeposit, setpinelabDeposit] = useState(true)
  const [decentroAuto, setdecentroAuto] = useState(false)
  const [settingId, setSettingId] = useState('')
  const [enable_otp, setEnableOtp] = useState()
  const [isCashFreeActive, setIsCashFree] = useState(true)
  const [isPhonePayActive, setIsPhoneActive] = useState(false)
  const [PayNowOne, setPayNowOne] = useState(true)
  const [PayNowTwo, setPayNowTwo] = useState(true)
  const [PayNowThree, setPayNowThree] = useState(true)
  const [payNowFour, setPayNowFour] = useState(true)
  const [isPayNowFive, setPayNowFive] = useState(true)


  const [KvmpayMin, SetPayMin] = useState(10)
const [KvmpayMax, SetPayMax] = useState(10)
const [upiMin, SetUpiMin] = useState(10)
const [upiMax, SetUpiMax] = useState(10)
const [UpiGatewayKey, setUpiGatewayKey] = useState(10)
const [khelstarUpiGateway, setkhelstarUpiGateway] = useState(10)

  // images manual payment
const [payOneImage,setPayOneImage] = useState(null)
const [payTwoImage,setPayTwoImage] = useState(null)
const [payThreeImage,setPayThreeImage] = useState(null)
const [payFourImage,setPayFourImage] = useState(null)
const [payFiveImage,setPayFiveImage] = useState(null)

  // console.log(enable_otp)
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }


  const grantAccess =async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    try {
      const res = await apiRequest(`gatewaysettings/data`,"GET",null,headers)
      console.log(res.data)
      setSettingId(res.data._id ? res.data._id : '')
      setRazorpayout(res.data.RazorPayout)
      setRazorDeposit(res.data.RazorDeposit)
      setRazorpayAuto(res.data.RazorpayAuto)
      setdecentropayout(res.data.decentroPayout)
      setdecentroDeposit(res.data.decentroDeposit)
      setdecentroAuto(res.data.decentroAuto)
      setRazorpayKey(res.data.RazorPayKey)
      setRazorpaysecretKey(res.data.RazorPaySecretKey)
      setAccountName(res.data.AccountName)
      setpinelabDeposit(res.data.pinelabDeposit)
      setEnableOtp(res.data.otp)
      setIsCashFree(res.data.isCashFreeActive)
      setIsPhoneActive(res.data.isPhonePayActive)
      setCheckedRozorPayIn(res.data.RazorPayout)
      setCheckedRozorPay(res.data.RazorDeposit)
      SetPayMin(res.data.KvmpayMin)
      SetPayMax(res.data.KvmpayMax)
      SetUpiMin(res.data.upiMin)
      SetUpiMax(res.data.upiMax)
      setUpiGatewayKey(res.data.UpiGatewayKey)
      setkhelstarUpiGateway(res.data.khelstarUpiGateway)
      setCheckedQroPayIn(res.data.isQropayInActive)
      setCheckedQroPay(res.data.isQropayOutActive)
      setisKvmpayInActive(res.data.isKvmpayInActive)
      setIsUpiGateway(res.data.isUpiGateway)
      setKhelStar(res.data.isKhelstarUpiGateway)
      setisKvmpayOutActive(res.data.isKvmpayOutActive)
      setCheckedPhonepeIn(res.data.isPhonePayActive)
      setIspaytmWithdrawal(res.data.ispaytmWithdrawal)
      setPayNowOne(res.data.isPayNowOne)
      setPayNowTwo(res.data.isPayNowTwo)
      setPayNowThree(res.data.isPayNowThree)
      setPayNowFour(res.data.isPayNowFour)
      setPayNowFive(res.data.isPayNowFive)
      setPayOneImage(res.data.isPayOneImage)
      setPayTwoImage(res.data.isPayTwoImage)
      setPayThreeImage(res.data.isPayThreeImage)
      setPayFourImage(res.data.isPayFourImage)
      setPayFiveImage(res.data.isPayFiveImage)
      SetPayNow1Url(res.data.paynow1Url)
      SetPayNow2Url(res.data.paynow2Url)
      SetPayNow3Url(res.data.paynow3Url)
      SetPayNow4Url(res.data.paynow4Url)
      SetPayNow5Url(res.data.paynow5Url)
        } catch (e) {
          // setLoading(false)

        }
        finally {
          // setLoading(false)
        }
  } 

  useEffect(() => {
grantAccess()
   
  }, [])

  const handleSubmit1 = async e => {
    e.preventDefault()
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    const response = await axios.post(baseUrl + `gatewaysettings`, {
       checkedRozorPayIn,
      checkedRozorPay,
      checkedQroPayIn,
       checkedQroPay,
       isKvmpayInActive,
       isUpiGateway,
       isKhelstarUpiGateway,
       isKvmpayOutActive,
       KvmpayMin,
       KvmpayMax,
       upiMin,
       upiMax,
       UpiGatewayKey,
       khelstarUpiGateway,
       PayNowOne,
       PayNowTwo,
       PayNowThree,
       payNowFour,
       isPayNowFive,
       checkedPhonepeIn,
       ispaytmWithdrawal,
      settingId,
      RazorpayAuto,
      decentroPayout,
      decentroDeposit,
      decentroAuto,
      RazorPayKey,
      RazorPaySecretKey,
      AccountName,
      pinelabDeposit,
      otp: enable_otp,
      isCashFreeActive,
      payOneImage,
      payTwoImage,
      payThreeImage,
      payFourImage,
      payFiveImage,
      paynow1Url,
      paynow2Url,
      paynow3Url,
      paynow4Url,
      paynow5Url
    }, {headers})
    console.log(response.data.status)
    if (response.data.status === 'success') {
      alert('Settings submitted successfully')
    } else {
      alert('Settings Not Submitted')
    }
  }


  const handleChangeRozarpayIn = val => {
    setCheckedRozorPayIn(val)
  }
  const handleChangeRozarpay = val => {
    setCheckedRozorPay(val)
  }
  const handleChangeQroPayIn = val => {
    setCheckedQroPayIn(val)
  }
  const handleChangeQroPay = val => {
    setCheckedQroPay(val)
  }
  const handleChangeMyPayIn = val => {
    setisKvmpayInActive(val)
  }
  const handleChangeUpi = val => {
    setIsUpiGateway(val)
  }
  const handleChangeUpi2 = val => {
    setKhelStar(val)
  }
  const handleChangeMyPay = val => {
    setisKvmpayOutActive(val)
  }
  const handleChangePhonepeIn = val => {
    setCheckedPhonepeIn(val)
  }
  const handleChangePaytm = val => {
    setIspaytmWithdrawal(val)
  }
  const handleChangePayOne = val => {
    setPayNowOne(val)
  }
  const handleChangeCashfree = val => {
    setIsCashFree(val)
  }
  const handleChangePayTwo = val => {
    setPayNowTwo(val)
  }
  const handleChangePayThree = val => {
    setPayNowThree(val)
  }
  const handleChangePayFour = val => {
    setPayNowFour(val)
  }
  const handleChangePayFive = val => {
    setPayNowFive(val)
  }

  const uploadImage = (type)=>{
  if(!type){
    return false;
  }
    var formdata = new FormData();
    if(type == "PayNowOne"){
      formdata.append("status", PayNowOne);
      formdata.append("image", payOneImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow1Url", paynow1Url);
    }else if(type == "PayNowTwo"){
      formdata.append("status", PayNowTwo);
      formdata.append("image", payTwoImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow2Url", paynow2Url);
    }else if(type == "PayNowThree"){
      formdata.append("status", PayNowThree);
      formdata.append("image", payThreeImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow3Url", paynow3Url);
    }else if(type == "PayNowFour"){
      formdata.append("status", payNowFour);
      formdata.append("image", payFourImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow4Url", paynow4Url);
    }else{
      formdata.append("status", isPayNowFive);
      formdata.append("image", payFiveImage);
      formdata.append("settingId", settingId);
      formdata.append("paynow5Url", paynow5Url);
    }

formdata.append("image_type", type);


var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(baseUrl+"gatewaysettings/change_qr", requestOptions)
  .then(response => {
    Swal.fire(
      'Success!',
      'Result Submit Successful',
      'success'
    )
    // window.location.reload(true)
  })
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  }

  return (
    <>
      <div className='card mt-5'>
        <div className='card-body  table_bg'>
          <h4 className='dashboard_heading my-3'>Payment Gateway Settings</h4>

          {/* <h4 className='my-3 dashboard_small_heading_dark'>Pay In Or Pay Out</h4> */}
           

          {/* <h5 className='dashboard_small_heading_dark my-3'>RazorPay</h5> */}

          <form
            action='gatewaysettings'
            className='form'
            onSubmit={handleSubmit1}
            method='patch'
            enctype='multipart/form-date'
          >

<div className='form-row'>
            
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      RozorPay
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>RozorPay In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedRozorPayIn}
                            onChange={handleChangeRozarpayIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>RozorPay out</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedRozorPay}
                            onChange={handleChangeRozarpay}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
         
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      KVM PAY
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>KvmPay In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isKvmpayInActive}
                            onChange={handleChangeMyPayIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>KvmPay out</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isKvmpayOutActive}
                            onChange={handleChangeMyPay}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Kvm Mini</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='ludokingroomcodeURL'
                            value={KvmpayMin}
                            onChange={e =>
                              SetPayMin(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                      <Col sm={6}>Kvm Max</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='ludokingroomcodeURL'
                            value={KvmpayMax}
                            onChange={e =>
                              SetPayMax(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>

              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Upi Deposit Limit
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Upi Min</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='ludokingroomcodeURL'
                            value={upiMin}
                            onChange={e =>
                              SetUpiMin(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Upi Max</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='UpiMax'
                            value={upiMax}
                            onChange={e =>
                              SetUpiMax(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Upi Gateway Key
                    </h4>
                    <Container>

                    <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UpiGateway ON</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isUpiGateway}
                            onChange={handleChangeUpi}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                    <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UpiGateway ON 2</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isKhelstarUpiGateway}
                            onChange={handleChangeUpi2}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}> Upi Gateway Key</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={UpiGatewayKey}
                            onChange={e =>
                              setUpiGatewayKey(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}> Upi Gateway Key 2</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={khelstarUpiGateway}
                            onChange={e =>
                              setkhelstarUpiGateway(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Key'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>

              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Phonepe Deposit
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Phonepe In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedPhonepeIn}
                            onChange={handleChangePhonepeIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Paytm Withdrawal
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Paytm Withdrawal</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={ispaytmWithdrawal}
                            onChange={handleChangePaytm}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>

              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                    Cashfree
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isCashFreeActive}
                            onChange={handleChangeCashfree}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                    

                     
                    
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>

            </div>

            {/* <div className='form-row'>
              <div className='form-group col-md-4'>
                <label htmlFor='buttonrazpay' className=' mt-2'>
                  Razorpay Payout
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={RazorPayout}
                  onChange={e => setRazorpayout(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div>
              <div className='form-group col-md-4'>
                <label htmlFor='buttonrazdep' className=' mt-2'>
                  Razorpay Deposit
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={RazorDeposit}
                  onChange={e => setRazorDeposit(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div>
              <div className='form-group col-md-4'>
                <label htmlFor='buttonrazauto' className=' mt-2'>
                  RazorPay Auto
                </label>
                <select
                  className='form-control'
                  name=''
                  id=''
                  value={RazorpayAuto}
                  onChange={e => setRazorpayAuto(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div>
            </div>

            <div className='form-row'>
              <div className='form-group col-md-4'>
                <label htmlFor='RazorpayKey'>RazorPay Key</label>
                <input
                  className='form-control'
                  type='text'
                  value={RazorPayKey}
                  onChange={e => setRazorpayKey(e.target.value)}
                />
              </div>

              <div className='form-group col-md-4'>
                <label htmlFor='RazorpaysecretKey'>RazorPay SecretKey</label>
                <input
                  className='form-control'
                  type='text'
                  value={RazorPaySecretKey}
                  onChange={e => setRazorpaysecretKey(e.target.value)}
                />
              </div>

              <div className='form-group col-md-4'>
                <label htmlFor='AccountName'>Account Name</label>
                <input
                  className='form-control'
                  type='text'
                  value={AccountName}
                  onChange={e => setAccountName(e.target.value)}
                />
              </div>
            </div>

            <h5 className='dashboard_small_heading_dark my-3'>Decentro</h5>
            <div className='form-row'>
              <div className='form-group col-md-4'>
                <label htmlFor='buttondecpay' className=' mt-2'>
                  Decentro payout
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={decentroPayout}
                  onChange={e => setdecentropayout(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div>
              <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2'>
                  Decentro Deposit
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={decentroDeposit}
                  onChange={e => setdecentroDeposit(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div>
              <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2'>
                  Decentro Auto
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={decentroAuto}
                  onChange={e => setdecentroAuto(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div>
            </div> */}

            {/* <h5 className='text-uppercase font-weight-bold my-3'>Pinelab</h5> */}
            <div className='form-row '>
              {/* <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2'>
                  Pinelab Deposit
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={pinelabDeposit}
                  onChange={e => setpinelabDeposit(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div> */}
              <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2'>
                  Enable Otp
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={enable_otp}
                  onChange={e => setEnableOtp(e.target.value)}
                >
                  <option value='1'>Enable</option>
                  <option value='0'>Disable</option>
                </select>
              </div>
              {/* <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2 '>
                  Is Cash Free
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={isCashFreeActive}
                  onChange={e => setIsCashFree(e.target.value)}
                >
                  <option value={true}>Enable</option>
                  <option value={false}>Disable</option>
                </select>
              </div> */}
              {/* <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2 '>
                  Phonepe PG
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={isPhonePayActive}
                  onChange={e => setIsPhoneActive(e.target.value)}
                >
                  <option value={true}>Enable</option>
                  <option value={false}>Disable</option>
                </select>
              </div> */}
            </div>

            <div className='form-row'>
              <div className='form-group col-md-4'>
                <button type='submit' className='btn btn-dark'>
                  submit
                </button>
              </div>
            </div>
          </form>
     
         
          <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 1
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={PayNowOne}
                            onChange={handleChangePayOne}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UPI ID</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={paynow1Url}
                            onChange={e =>
                              SetPayNow1Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayOneImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>

                     
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payOneImage} target='_blank'>
                          <img src={baseUrl + payOneImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowOne')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              {/* <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 2
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={PayNowTwo}
                            onChange={handleChangePayTwo}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Number</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='url'
                            name='ludokingroomcodeURL'
                            value={paynow2Url}
                            onChange={e =>
                              SetPayNow2Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Url'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayTwoImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payTwoImage} target='_blank'>
                          <img src={baseUrl + payTwoImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}/>
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowTwo')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 3
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={PayNowThree}
                            onChange={handleChangePayThree}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Number</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='url'
                            name='ludokingroomcodeURL'
                            value={paynow3Url}
                            onChange={e =>
                              SetPayNow3Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Url'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayThreeImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payThreeImage} target='_blank'>
                          <img src={baseUrl + payThreeImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowThree')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 4
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={payNowFour}
                            onChange={handleChangePayFour}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Number</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='url'
                            name='ludokingroomcodeURL'
                            value={paynow4Url}
                            onChange={e =>
                              SetPayNow4Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Url'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayFourImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payFourImage} target='_blank'>
                          <img src={baseUrl + payFourImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowFour')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 5
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isPayNowFive}
                            onChange={handleChangePayFive}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Number</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='url'
                            name='ludokingroomcodeURL'
                            value={paynow5Url}
                            onChange={e =>
                              SetPayNow5Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Url'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayFiveImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payFiveImage} target='_blank'>
                          <img src={baseUrl + payFiveImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('isPayNowFive')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div> */}
        </div>
      </div>
    </>
  )
}

export default Gateway
